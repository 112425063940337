import _toConsumableArray from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { resourceTypeCheckboxType } from "../../assets/js/data";
import SearchSelect from "../../components/common/Search";
export default {
  name: "ResourceType",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '资源类型状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '资源类型名称',
        value: '',
        type: 'text',
        list: []
      }],
      sum: "",
      //类型循环的所有id加起来
      resourceTypeCheckboxType: resourceTypeCheckboxType,
      resourceTypeCheckboxTypeList: _toConsumableArray(resourceTypeCheckboxType.list),
      //类型循环的数据
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        name: "",
        code: "",
        rank: "",
        state: 1,
        createdAt: "",
        type: "" //类型
      },

      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {},
  components: {
    SearchSelect: SearchSelect
  },
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    //筛选功能
    search: function search(filterArr) {
      var _this = this;
      this.service.axiosUtils({
        requestName: "getResTypeList",
        data: {
          name: filterArr[1].value,
          state: filterArr[0].value,
          // userId:this.userInfo.userId,
          // roleId:this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.tableData = res.data.data;
            _this.total = res.data.total;
            _this.initType();
          } else {
            _this.$message.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    //类型选择有改变的时候
    checkedItem: function checkedItem(value) {
      console.log(this.drawerData);
      // this.resourceTypeCheckboxTypeList[]
    },
    // 添加函数
    addFun: function addFun() {
      // this.clearDrawerData();
      this.initType();
      this.drawerState = "add";
      this.drawer = true;
    },
    //获取下拉
    resourcetypeGetAll: function resourcetypeGetAll() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'resourcetypeGetAll',
        data: {
          courseId: this.$route.query.courseId
        },
        sucFn: function sucFn(res) {
          _this2.resourcetypeDrop = res.data;
          console.log(res.data);
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: "getResTypeList",
        data: {
          // userId:this.userInfo.userId,
          // roleId:this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.tableData = res.data.data;
            _this3.total = res.data.total;
            _this3.initType();
          } else {
            _this3.$message.error(res.message);
          }
        }
      });
    },
    //新建初始化数据
    clearDrawerData: function clearDrawerData() {
      this.drawerData = {
        name: "",
        code: "",
        rank: "",
        state: "",
        type: ""
      };
      this.initType();
      this.$refs.form.resetFields();
    },
    //初始化type
    initType: function initType() {
      //判断选中的类型
      this.sum = 0;
      for (var i = 0; i < this.resourceTypeCheckboxType.list.length; i++) {
        var id = this.resourceTypeCheckboxType.list[i].id;
        // this.sum+=id;
        //初始化数据
        this.resourceTypeCheckboxTypeList[i].isChecked = false;
      }
      this.drawerData.type = this.sum;
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this4 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "changeResTypeState",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this5 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        setTimeout(function () {
          _this5.drawerData = {
            name: val.name,
            code: val.code,
            rank: val.rank,
            state: val.state,
            id: val.id,
            type: val.type
          };
          //转换type
          for (var i = 0; i < _this5.resourceTypeCheckboxTypeList.length; i++) {
            var id = _this5.resourceTypeCheckboxTypeList[i].id;
            if ((_this5.drawerData.type & id) > 0) {
              _this5.resourceTypeCheckboxTypeList[i].isChecked = true;
            } else {
              _this5.resourceTypeCheckboxTypeList[i].isChecked = false;
            }
          }
        }, 30);
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      /*      this.$nextTick(function () {
              this.$refs["form"].resetFields();
            });*/
      this.$refs["form"].resetFields();
      this.drawer = false;
    },
    //传递的参数处理转换
    setPostData: function setPostData() {
      this.drawerData.type = 0;
      for (var i = 0; i < this.resourceTypeCheckboxTypeList.length; i++) {
        if (this.resourceTypeCheckboxTypeList[i].isChecked == true) {
          this.drawerData.type += this.resourceTypeCheckboxTypeList[i].id;
        }
      }
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var _this6 = this;
      var that = this;
      this.setPostData();
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === "edit") {
            //修改
            url = "editResType";
          } else {
            //添加
            url = "addResType";
          }
          if (_this6.drawerData.type == 0) {
            _this6.$toast.warning("至少保留一个类型");
            return;
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearDrawerData();
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this7 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "deleteResType",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.$toast.success(res.message);
            _this7.delId = "";
            _this7.centerDialogVisible = false;
            _this7.initData();
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    }
  }
};